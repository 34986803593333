<template>
  <el-dialog
      title="款式编辑"
      width="75%"
      :visible.sync="dialogState"
      :modal-append-to-body="false"
      @close="close"
      center>
    <el-row>
      <el-col :span="10">
        <styleImage :styleInfo="styleInfo"  :state="true"/>
      </el-col>
      <el-col :span="12">
        <el-form label-width="130px" :model="form" :rules="rules" ref="form">
          <el-row>
            <el-col :span="12">
              <el-form-item label="款式类型" prop="styleType">
                <el-select v-model="form.styleType" disabled placeholder="款式类型" clearable class="searchParam">
                  <el-option
                      v-for="item in styleTypeIdArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="店铺款式编号" prop="styleName">
                <el-input readonly v-model="form.styleName" class="searchParam">
                  <template slot="append">
                    <el-tooltip class="item" effect="dark" content="店铺内部编号" placement="top-start">
                      <i class="el-icon-question"></i>
                    </el-tooltip>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>

          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="款式名称">
                <el-input v-model="form.styleAlias" class="searchParam"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="品牌款式编号" prop="factoryNumber">
                <el-input v-model="form.factoryNumber" class="searchParam">
                  <template slot="append">
                    <el-tooltip class="item" effect="dark" content="品牌官方编号" placement="top-start">
                      <i class="el-icon-question"></i>
                    </el-tooltip>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="品牌款式名称" prop="factoryName">
                <el-input v-model="form.factoryName" class="searchParam">
                  <template slot="append">
                    <el-tooltip class="item" effect="dark" content="品牌官方款式名称" placement="top-start">
                      <i class="el-icon-question"></i>
                    </el-tooltip>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="选择品牌">
                <el-select v-model="form.brandId" placeholder="选择品牌" clearable class="searchParam">
                  <el-option
                      v-for="item in brandIdArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="选择标签">
                <el-select v-model="form.styleLabels" collapse-tags multiple placeholder="选择标签" clearable class="searchParam">
                  <el-option
                      v-for="item in styleLabelIdArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="试纱次数">
                <el-input readonly v-model="count.yarnCount" class="searchParam">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="出件次数">
                <el-input readonly v-model="count.outCount" class="searchParam">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="款式介绍" prop="styleInfo">
                <el-input type="textarea" v-model="form.styleInfo"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="适合身形" prop="styleFit">
                <el-input  type="textarea" v-model="form.styleFit"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="不适合身形" prop="styleNoFit">
                <el-input type="textarea" v-model="form.styleNoFit"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
    </el-row>

    <span slot="footer" class="dialog-footer">
      <el-button @click="close" size="medium">取 消</el-button>
      <el-button type="primary" @click="updateStyleCheck()" size="medium">提 交</el-button>
      <el-button v-if="form.tenantCrop==='2a31c23a-c178-441614928053489'" type="success" icon="el-icon-refresh" size="medium" @click="syncStyleInfo()">同步到贸易</el-button>
    </span>
  </el-dialog>
</template>

<script>
import styleImage from "@/pages/style/style-image";
export default {
  name: "style-edit",
  data() {
    return {
      form: {
        id: this.styleInfo.id,
        styleType: "",
        styleName:this.styleInfo.styleName,
        factoryName:this.styleInfo.factoryName,
        factoryNumber:this.styleInfo.factoryNumber,
        brandId:"",
        styleLabels:"",
        styleAlias:this.styleInfo.styleAlias,
        styleInfo:this.styleInfo.styleInfo,
        styleFit:this.styleInfo.styleFit,
        styleNoFit:this.styleInfo.styleNoFit,

        tenantCrop: localStorage.getItem("tenantCrop"),
      },
      styleTypeIdArray:[],
      brandIdArray:[],
      styleLabelIdArray:[],
      rules: {
        styleType: [
          {required: true, message: '请输入婚纱尺寸', trigger: 'change'},
        ],
        styleName: [
          {required: true, message: '请输入婚纱名称', trigger: 'blur'},
        ],

      },
      dialogState: this.state,
      count: {
        yarnCount: 0,
        outCount: 0,
      }
    }
  },
  components:{
    styleImage
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
    styleInfo: {
      type: Object,
      required: true,
    }
  },
  created() {
    this.queryStyleTypeIdsArr();
    this.queryBrandIdsArr();
    this.queryLabelIdsArr();
    this.queryCount()
  },
  methods: {
    //检查订单校验
    updateStyleCheck: function () {
      console.log(this.form)
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.updateStyle();
        }
      })
    },
    //添加店铺
    updateStyle: function () {
      let value = "是否修改该款式?"
      this.$confirm(value, '修改款式:', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method: "PUT",
          url: "/style/editStyle",
          params: {
            id: this.form.id,
            styleType: this.form.styleType,
            styleName:this.form.styleName,
            factoryName:this.form.factoryName,
            factoryNumber:this.form.factoryNumber,
            brandId:this.form.brandId,
            styleAlias:this.form.styleAlias,
            styleLabels:this.form.styleLabels.toString(),
            styleInfo:this.form.styleInfo,
            styleFit:this.form.styleFit,
            styleNoFit:this.form.styleNoFit,
            tenantCrop:this.form.tenantCrop,
          }
        }).then(response => {
          response.data.code === 200
              ?this.$message.success("修改成功!") :this.$message.error(response.data.msg);
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    //查询款式类型
    queryStyleTypeIdsArr(){
      this.$selectUtils.queryStyleTypeIds().then(response=>{
        this.styleTypeIdArray=JSON.parse(response.data.data);
        this.form.styleType=this.styleInfo.styleType;
      })
    },
    //查询品牌类型
    queryBrandIdsArr(){
      this.$selectUtils.queryBrandIds().then(response=>{
        this.brandIdArray=JSON.parse(response.data.data);
        this.form.brandId=this.styleInfo.brandId;
      })
    },
    //查询标签类型
    queryLabelIdsArr(){
      this.$selectUtils.queryLabelIds(this.$labelType.style).then(response=>{
        this.styleLabelIdArray=response.data.data;
        this.form.styleLabels=this.styleInfo.styleLabels===""?"":this.styleInfo.styleLabels.split(",").map(Number);
      })
    },
    syncStyleInfo(){
      this.$axios({
        method:"POST",
        url:"/libraryStyle/syncStyleInfoByStyleId",
        params:{
          styleId:this.styleInfo.id,
        }
      }).then(response=>{
        response.data.code===200?this.$message.success('同步成功'):this.$message.error(response.data.msg);
      })
    },
    close: function () {
      this.$emit("close", false);
    },
    queryCount() {
      this.$axios({
        method: "get",
        url: "/style/queryYarnCountAndOutCountByStyleId",
        params: {
          styleId: this.form.id,
          tenantCrop: localStorage.getItem("tenantCrop"),
        }
      }).then(response => {
        this.count = response.data.data
      })
    }
  }
}
</script>

<style scoped>
.searchParam{
  width: 200px;
}
</style>