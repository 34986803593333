<template>
  <div>
    <fieldset style="width:97%">
      <legend>搜索信息</legend>
      <el-row :gutter="20">
        <el-col :span="3">
          <el-select v-model="form.styleType" filterable placeholder="款式类型" clearable class="searchParam" size="medium">
            <el-option
                v-for="item in styleTypeIdArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-input v-model="form.styleName" placeholder="款式编号" size="medium"></el-input>
        </el-col>
        <el-col :span="3">
          <el-input v-model="form.styleAlias" placeholder="款式名称" size="medium"></el-input>
        </el-col>
        <el-col :span="3">
          <el-select v-model="form.brandId" filterable placeholder="款式品牌" clearable class="searchParam" size="medium">
            <el-option
                v-for="item in brandIdArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="form.styleLabels" filterable multiple placeholder="款式标签" clearable class="searchParam" size="medium">
            <el-option
                v-for="item in labelIdArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" @click="search" size="medium">搜索</el-button>
        </el-col>
      </el-row>
    </fieldset>
    <div class="infinite-list-wrapper">
      <el-row :gutter="15" style="margin: 0 auto;" class="list" v-infinite-scroll="load" :infinite-scroll-disabled="disabled">
        <el-col :span="4" v-for="item in styleList" :key="item.id" style="margin: 1% 0 0 0" class="list-item">
          <div style="text-align: center">
            <el-badge :value="item.brandName===''?'无':item.brandName" class="item" type="primary">
              <div style="width: 14vw;height: 19vw">
              <img style="width: 100%; height: 100%;cursor:zoom-in;" @click="openStyleImages(item.id)" alt="请重新设置主图"
                  :src="'https://clothes-image-1304365928.cos.ap-shanghai.myqcloud.com/'+item.styleImage+'?imageMogr2/rquality/60'"/>
              </div>
            </el-badge>

            <el-row :gutter="5" style="width: 14vw; margin: 0 auto">
              <el-col :span="12">
                <p class="pTitle" style="text-align: left;font-weight: bold;font-size: 13px">{{ item.typeName+'-'+item.styleName }}</p>
              </el-col>
              <el-col :span="12">
                <p class="pTitle" style="text-align: right;font-weight: bold;font-size: 13px">{{item.styleAlias===''?'无':item.styleAlias}}</p>
              </el-col>
            </el-row>
            <el-row class="link-button" style="width: 14vw;margin: 0.3vh auto 0 auto;">
              <el-col :span="3" style="text-align: left">
                <el-button class="clothesName" type="text" @click="openView('edit',item)" style="color:#656565;font-weight: bold ">编辑</el-button>
              </el-col>
              <el-col :span="3" style="text-align: center">
                <el-button class="clothesName" type="text" @click="openView('delStyle',item)" style="color:#656565 ;font-weight: bold">删除</el-button>
              </el-col>
              <el-col :span="6" style="text-align: center">
                <el-button class="clothesName" type="text" @click="openView('stylePrice',item)" style="color:#656565 ;font-weight: bold">款式价格</el-button>
              </el-col>
              <el-col :span="5" style="text-align: right">
                <el-button class="clothesName" type="text" @click="openView('addClothes',item)" style="color:#656565 ;font-weight: bold">添加婚纱</el-button>
              </el-col>

            </el-row>
          </div>
        </el-col>
      </el-row>
      <p  v-loading="loading" element-loading-text="加载中"></p>
      <p v-if="noMore">没有更多了...</p>
    </div>
    <clothesAdd v-if="dialog.addClothesState" :styleInfo="dialog.style" :state="dialog.addClothesState" @close="close"/>
    <styleEdit v-if="dialog.openEditState" :styleInfo="dialog.style" :state="dialog.openEditState" @close="close"/>
    <styleImage v-if="dialog.openImageState" :styleInfo="dialog.style" :state="dialog.openImageState" @close="close"/>
    <stylePrice v-if="dialog.openStylePrice" :styleInfo="dialog.style" :state="dialog.openStylePrice" @close="close"/>
  </div>
</template>

<script>
import clothesAdd from "@/pages/clothes/clothes-add";
import styleEdit from "@/pages/style/style-edit";
import styleImage from "@/pages/style/style-image";
import stylePrice from "@/pages/style/style-price";

export default {
name: "style-list",
  data(){
    return{
      form:{
        styleType:"",
        styleName:"",
        styleAlias:"",
        brandId:"",
        styleLabels:"",
        tenantCrop:localStorage.getItem("tenantCrop"),
      },
      styleTypeIdArray:[],
      brandIdArray:[],
      labelIdArray:[],
      dialog:{
        addClothesState:false,
        openEditState:false,
        openImageState:false,
        openStylePrice:false,
        style:{},
      },

      page:1,
      styleList:[],
      total:0,
      loading: true,
      disabled:true,
      noMore:false,
    }
  },
  components:{
    clothesAdd,styleEdit,styleImage,stylePrice
  },
  created() {
    this.queryStyleList();
    this.queryStyleTypeIdsArr();
    this.queryBrandIdsArr();
    this.queryLabelIdsArr();
  },
  methods:{
    search(){
      this.styleList=[];
      this.page=1;
      this.total=0;
      this.queryStyleList();
    },
    //查询客户列表
    queryStyleList:function (){
      this.$axios({
        method:"GET",
        url:"/style/styleList",
        params:{
          page:this.page,
          limit:30,
          styleType: this.form.styleType,
          styleName: this.form.styleName,
          tenantCrop: this.form.tenantCrop,
          styleLabels: this.form.styleLabels.toString(),
          brandId: this.form.brandId,
          styleAlias: this.form.styleAlias,
        }
      }).then(response=>{
        this.styleList.push(...response.data.data.list);
        console.log(this.styleList)
        this.total=response.data.data.total;
        this.loading = false
        this.disabled = false;
      })
    },
    openView(action,item) {
      this.$axios({
        method:"GET",
        url:"/style/queryStyleDetails",
        params:{
          styleId:item.id
        }
      }).then(response=>{
        this.dialog.style=response.data.data;
        switch (action){
          case 'edit':
            this.dialog.openEditState = true
            break;
          case 'addClothes':
            this.dialog.addClothesState = true
            break;
          case 'styleImage':
            this.dialog.openImageState = true
            break;
          case 'stylePrice':
            this.dialog.openStylePrice = true
            break;
          case 'delStyle':
            this.deleteStyle(item);
            break;
        }
      })

    },
    //删除客户
    deleteStyle(data){
      let value='是否删除款式 : <span style="color: red">'+data.typeName+'-'+data.styleName+"</span> ?";
      this.$confirm(value, '删除款式', {
        dangerouslyUseHTMLString:true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$axios({
          method:"POST",
          url:"/style/deleteStyle",
          params:{
            styleId:data.id
          }
        }).then(response=>{
          if (response.data.code === 200) {
            this.$message.success("删除成功!")
            let self=this;
            setTimeout(function (){self.queryStyleList()},1000)
          } else {
            this.$message.error(response.data.msg)
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    //查询款式类型
    queryStyleTypeIdsArr(){
      this.$selectUtils.queryStyleTypeIds().then(response=>{
        this.styleTypeIdArray=JSON.parse(response.data.data);
      })
    },
    //查询品牌类型
    queryBrandIdsArr(){
      this.$selectUtils.queryBrandIds().then(response=>{
        this.brandIdArray=JSON.parse(response.data.data);
      })
    },
    //查询标签类型
    queryLabelIdsArr(){
      this.$selectUtils.queryLabelIds(this.$labelType.style).then(response=>{
        this.labelIdArray=response.data.data;
      })
    },
    openStyleImages(styleId){
      this.$axios({
        method: "GET",
        url: "/styleImage/queryLinkListByStyleId",
        params: {
          styleId: styleId
        }
      }).then(response => {
        this.$viewerApi({
          images: response.data.data,
        })
      })
    },
    //更改每页长度
    handleSizeChange:function (val){
      this.table.limit=val;
      this.queryStyleList();
    },
    handleCurrentChange(val) {
      this.table.page =val
      this.queryStyleList()
    },
    //加载
    load() {
      this.disabled=true;
      if (this.styleList.length>=this.total){
        this.noMore=true;
        this.loading=false;
        return;
      }
      this.loading = true;
      this.noMore=false;
      this.page++;
      setTimeout(() => {
        this.queryStyleList();
      }, 1000)
    },
    close(){
      this.dialog.addClothesState=false;
      this.dialog.openEditState=false;
      this.dialog.openImageState=false;
      this.dialog.openStylePrice=false;
    }
  }
}
</script>

<style scoped>
p{
  margin: 5% 0;
  font-size: 23px;
  text-align: center;
}
.pTitle{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  margin: 0 0;
  font-size: 13px;
  font-weight: bold;
}
.item >>>.el-badge__content.is-fixed{
  transform:none;
  left: 0;
  right: unset ;
}
.link-button>>>.el-button{
  padding: unset;
}
</style>